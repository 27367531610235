import React from "react";

const Header = () => {
  return (
    <>
      <div id="site-topbar" className="site-topbar">
        <div className="wrap">
          <div className="site-topbar-inner">
            <div className="topbar-text">
              <a href="/">
                <img
                  style={{ width: 350, height: "auto" }}
                  className="logo logoDefault"
                  data-lazy-src="/bajrangilogo.png"
                  alt="Bajrangi Logo"
                />
              </a>
            </div>

            {/* /.topbar-text */}
            <div className="topbar-menu">
              <ul id="menu-top-menu" className="menu menu-top">
                <li
                  id="menu-item-2413"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2413"
                >
                  <a href="about/index.html" data-ps2id-api="true">
                    About
                  </a>
                </li>
                <li
                  id="menu-item-2414"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2414"
                >
                  <a href="it-services/index.html" data-ps2id-api="true">
                    Services
                  </a>
                </li>
                <li
                  id="menu-item-1804"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1804"
                >
                  <a href="it-blog/index.html" data-ps2id-api="true">
                    Blog
                  </a>
                </li>
                <li
                  id="menu-item-2412"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2412"
                >
                  <a href="case-studies/index.html" data-ps2id-api="true">
                    Cases
                  </a>
                </li>
                <li
                  id="menu-item-1756"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1756"
                >
                  <a href="contact-us/index.html" data-ps2id-api="true">
                    Contact
                  </a>
                </li>
              </ul>{" "}
              {/* /.topbar-menu */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
