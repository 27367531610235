import React from "react";

const OffCanvas = () => {
  return (
    <>
      <div id="off-canvas-right" className="off-canvas sliding-menu">
        <a
          href="javascript:;"
          data-target="off-canvas-right"
          className="off-canvas-toggle"
        >
          <span />
        </a>
        <div className="off-canvas-wrap">
          <div className="widget widget_search">
            <form
              role="search"
              method="get"
              className="search-form"
              action=""
            >
              <label>
                <span className="screen-reader-text">Search for:</span>
                <input
                  type="search"
                  className="search-field"
                  placeholder="Search …"
                  defaultValue=""
                  name="s"
                />
              </label>
              <input
                type="submit"
                className="search-submit"
                defaultValue="Search"
              />
            </form>
          </div>
          <ul id="menu-mobile" className="menu menu-sliding">
            <li
              id="menu-item-1465"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-1465"
            >
              <a href="index.html" aria-current="page" data-ps2id-api="true">
                Home
              </a>
            </li>
            <li
              id="menu-item-797"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-797"
            >
              <a href="#" data-ps2id-api="true">
                Company
              </a>
              <ul className="sub-menu">
                <li
                  id="menu-item-270"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-270"
                >
                  <a href="about/index.html" data-ps2id-api="true">
                    About
                  </a>
                </li>
                <li
                  id="menu-item-792"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-792"
                >
                  <a href="faq/index.html" data-ps2id-api="true">
                    FAQ
                  </a>
                </li>
                <li
                  id="menu-item-791"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-791"
                >
                  <a href="careers/index.html" data-ps2id-api="true">
                    Careers
                  </a>
                </li>
                <li
                  id="menu-item-1335"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1335"
                >
                  <a href="pricing-and-plans/index.html" data-ps2id-api="true">
                    Pricing and plans
                  </a>
                </li>
              </ul>
            </li>
            <li
              id="menu-item-790"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-790"
            >
              <a href="#" data-ps2id-api="true">
                IT Services
              </a>
              <ul className="sub-menu">
                <li
                  id="menu-item-780"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-780"
                >
                  <a
                    href="it-services/managed-it/index.html"
                    data-ps2id-api="true"
                  >
                    Managed IT
                  </a>
                </li>
                <li
                  id="menu-item-781"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-781"
                >
                  <a
                    href="it-services/it-support/index.html"
                    data-ps2id-api="true"
                  >
                    IT Support
                  </a>
                </li>
                <li
                  id="menu-item-779"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-779"
                >
                  <a
                    href="it-services/it-consultancy/index.html"
                    data-ps2id-api="true"
                  >
                    IT Consultancy
                  </a>
                </li>
                <li
                  id="menu-item-778"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-778"
                >
                  <a
                    href="it-services/cloud-computing/index.html"
                    data-ps2id-api="true"
                  >
                    Cloud Computing
                  </a>
                </li>
                <li
                  id="menu-item-777"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-777"
                >
                  <a
                    href="it-services/cyber-security/index.html"
                    data-ps2id-api="true"
                  >
                    Cyber Security
                  </a>
                </li>
                <li
                  id="menu-item-776"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-776"
                >
                  <a
                    href="it-services/custom-software/index.html"
                    data-ps2id-api="true"
                  >
                    Custom Software
                  </a>
                </li>
                <li
                  id="menu-item-775"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-775"
                >
                  <a href="it-services/index.html" data-ps2id-api="true">
                    All Services
                  </a>
                </li>
              </ul>
            </li>
            <li
              id="menu-item-279"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-279"
            >
              <a href="case-studies/index.html" data-ps2id-api="true">
                Case Studies
              </a>
            </li>
            <li
              id="menu-item-280"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-280"
            >
              <a href="it-blog/index.html" data-ps2id-api="true">
                IT Blog
              </a>
            </li>
            <li
              id="menu-item-271"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-271"
            >
              <a href="contact-us/index.html" data-ps2id-api="true">
                Contact Us
              </a>
            </li>
          </ul>
          <div className="social-icons">
            <a href="#" data-tooltip="facebook" target="_blank">
              <i className="fa fa-facebook-f" />
            </a>
            <a href="#" data-tooltip="twitter" target="_blank">
              <i className="fa fa-twitter" />
            </a>
            <a href="#" data-tooltip="linkedin" target="_blank">
              <i className="fa fa-linkedin" />
            </a>
            <a href="#" data-tooltip="installgram" target="_blank">
              <i className="fa fa-instagram" />
            </a>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default OffCanvas;
