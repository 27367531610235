import logo from "./logo.svg";
import "./App.css";

import Layout from "./Component/Layout";
import Home from "./Pages/Home";

function App() {
  return (
    <>
      <Layout>
        <Home />
      </Layout>
    </>
  );
}

export default App;
