import React from "react";

const Footer = () => {
  return (
    <>
      <div id="site-footer" className="site-footer">
        <div className="content-bottom-widgets">
          <div className="content-bottom-inner wrap">
            <div className="content-bottom-aside-wrap">
              <aside data-width={6}>
                <div
                  id="text-6"
                  className="widget-odd widget-first widget-1 widget widget_text"
                >
                  <h3 className="widget-title">Let’s get started</h3>{" "}
                  <div className="textwidget">
                    <h2 className="no-margin-top">
                      We will help you overcome your technology challenges
                    </h2>
                    <p>
                      Call us on{" "}
                      <a className="link" href="#">
                        (0712) 819 79 555
                      </a>{" "}
                      or{" "}
                      <a className="link" href="#">
                        (0712) 819 79 999
                      </a>{" "}
                      email us at{" "}
                      <a className="link" href="#">
                        contact@example.com
                      </a>{" "}
                      or fill out the following form to start the conversation.
                    </p>
                  </div>
                </div>
                <div
                  id="text-26"
                  className="widget-even widget-last widget-2 widget widget_text"
                >
                  {" "}
                  <div className="textwidget">
                    <div className="gr-btn">
                      <a
                        className="button primary"
                        href="contact-us/index.html"
                      >
                        START WITH A FREE ASSESSMENT
                      </a>
                      <a className="button accent" href="contact-us/index.html">
                        Find your Solutions
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </aside>
              <aside data-width={6}></aside>
            </div>
          </div>
        </div>
        <div className="footer-widgets">
          <div className="footer-widgets-inner wrap">
            <div className="footer-aside-wrap">
              <aside data-width={3}>
                <div
                  id="text-28"
                  className="widget-odd widget-last widget-first widget-1 widget widget_text"
                >
                  {" "}
                  <a href="/">
                    <img
                      style={{ width: 350, height: "auto" }}
                      className="logo logoDefault"
                      data-lazy-src="/BajrangiTechnnofooterlogo.png"
                      alt="Bajrangi Logo"
                    />
                  </a>
                </div>{" "}
              </aside>

              <aside data-width={6}>
                <div
                  id="nav_menu-18"
                  className="widget-odd widget-last widget-first widget-1 menu-style2 columns-4 widget widget_nav_menu"
                >
                  <div className="menu-footer-menu-container">
                    <ul id="menu-footer-menu" className="menu">
                      <li
                        id="menu-item-762"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-762"
                      >
                        <a href="it-services/index.html" data-ps2id-api="true">
                          IT Services
                        </a>
                        <ul className="sub-menu">
                          <li
                            id="menu-item-767"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-767"
                          >
                            <a
                              href="it-services/managed-it/index.html"
                              data-ps2id-api="true"
                            >
                              Managed IT
                            </a>
                          </li>
                          <li
                            id="menu-item-768"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-768"
                          >
                            <a
                              href="it-services/it-support/index.html"
                              data-ps2id-api="true"
                            >
                              IT Support
                            </a>
                          </li>
                          <li
                            id="menu-item-1950"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1950"
                          >
                            <a
                              href="it-services/cloud-computing/index.html"
                              data-ps2id-api="true"
                            >
                              Cloud Computing
                            </a>
                          </li>
                          <li
                            id="menu-item-1951"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1951"
                          >
                            <a
                              href="it-services/it-consultancy/index.html"
                              data-ps2id-api="true"
                            >
                              IT Consultancy
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        id="menu-item-562"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-562"
                      >
                        <a data-ps2id-api="true">Company</a>
                        <ul className="sub-menu">
                          <li
                            id="menu-item-564"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-564"
                          >
                            <a href="about/index.html" data-ps2id-api="true">
                              About Us
                            </a>
                          </li>
                          <li
                            id="menu-item-2146"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2146"
                          >
                            <a href="faq/index.html" data-ps2id-api="true">
                              FAQ
                            </a>
                          </li>
                          <li
                            id="menu-item-2145"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2145"
                          >
                            <a href="careers/index.html" data-ps2id-api="true">
                              Careers
                            </a>
                          </li>
                          <li
                            id="menu-item-1954"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1954"
                          >
                            <a
                              href="pricing-and-plans/index.html"
                              data-ps2id-api="true"
                            >
                              Pricing and plans
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        id="menu-item-1907"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1907"
                      >
                        <a href="#" data-ps2id-api="true">
                          Support
                        </a>
                        <ul className="sub-menu">
                          <li
                            id="menu-item-1910"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1910"
                          >
                            <a
                              href="https://linethemes.ticksy.com/"
                              data-ps2id-api="true"
                            >
                              Forum
                            </a>
                          </li>
                          <li
                            id="menu-item-2166"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2166"
                          >
                            <a href="it-blog/index.html" data-ps2id-api="true">
                              IT Blog
                            </a>
                          </li>
                          <li
                            id="menu-item-1908"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1908"
                          >
                            <a
                              href="contact-us/index.html"
                              data-ps2id-api="true"
                            >
                              Contact Us
                            </a>
                          </li>
                          <li
                            id="menu-item-1956"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1956"
                          >
                            <a href="#" data-ps2id-api="true">
                              Privacy Policy
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/* <li
                        id="menu-item-1957"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1957"
                      >
                        <a href="#" data-ps2id-api="true">
                          Follow Us
                        </a>
                        <ul className="sub-menu">
                          <li
                            id="menu-item-1958"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1958"
                          >
                            <a href="#" data-ps2id-api="true">
                              Facebook
                            </a>
                          </li>
                          <li
                            id="menu-item-1959"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1959"
                          >
                            <a href="#" data-ps2id-api="true">
                              Twitter
                            </a>
                          </li>
                          <li
                            id="menu-item-1960"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1960"
                          >
                            <a href="#" data-ps2id-api="true">
                              Linkedin
                            </a>
                          </li>
                          <li
                            id="menu-item-1961"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1961"
                          >
                            <a href="#" data-ps2id-api="true">
                              Youtube
                            </a>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </div>
                </div>{" "}
              </aside>
              <aside data-width={3}>
                <div
                  id="mc4wp_form_widget-14"
                  className="widget-odd widget-last widget-first widget-1 widget widget_mc4wp_form_widget"
                >
                  {/* Mailchimp for WordPress v4.8.6 - https://wordpress.org/plugins/mailchimp-for-wp/ */}
                  <form
                    id="mc4wp-form-1"
                    className="mc4wp-form mc4wp-form-126"
                    method="post"
                    data-id={126}
                    data-name="Newsletter"
                  >
                    <div className="mc4wp-form-fields">
                      <div className="sign-up style2">
                        <p>
                          Learn about IT Solutions with the monthly our
                          newsletter
                        </p>
                        <input
                          type="email"
                          name="EMAIL"
                          placeholder="Enter your Email"
                          required=""
                        />
                        <input type="submit" defaultValue="SUBSCRIBE" />
                      </div>
                    </div>
                    <label style={{ display: "none !important" }}>
                      Leave this field empty if you're human:{" "}
                      <input
                        type="text"
                        name="_mc4wp_honeypot"
                        defaultValue=""
                        tabIndex={-1}
                        autoComplete="off"
                      />
                    </label>
                    <input
                      type="hidden"
                      name="_mc4wp_timestamp"
                      defaultValue={1703317390}
                    />
                    <input
                      type="hidden"
                      name="_mc4wp_form_id"
                      defaultValue={126}
                    />
                    <input
                      type="hidden"
                      name="_mc4wp_form_element_id"
                      defaultValue="mc4wp-form-1"
                    />
                    <div className="mc4wp-response" />
                  </form>
                  {/* / Mailchimp for WordPress Plugin */}
                </div>{" "}
              </aside>
            </div>
            Developed by BajrangiTechno | © 2023 BajrangiTechno. All rights
            reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
