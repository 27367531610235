import React from "react";

const SideNav = () => {
  return (
    <>
      <div
        id="site-header"
        className="site-header site-header-classic header-brand-left header-shadow"
      >
        <div className="site-header-inner wrap">
          <div className="header-content">
            <div className="header-brand">
              {/* <a href="https://live.21lab.co/nanosoft-3">
                <img
                  width={300}
                  height={30}
                  alt="NanoSoft"
                  className="logo logoDefault"
                  data-lazy-src="/bajrangilogo.png"
                />
              </a> */}
            </div>
            <nav
              className="navigator"
              itemScope="itemscope"
              itemType="http://schema.org/SiteNavigationElement"
            >
              <div className="nav-info">
                <div className="aligncenter">
                  <p>
                    <a
                      href="contact-us/index.html"
                      className="button small accent square full"
                    >
                      SCHEDULE A DEMO
                    </a>
                  </p>
                  <div>Have any questions?</div>
                  <a href="#">support@linethemes.com</a>
                </div>{" "}
              </div>
              <ul id="menu-main-menu" className="menu menu-primary">
                <li
                  id="menu-item-1796"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-1796"
                >
                  <a
                    href="index.html"
                    aria-current="page"
                    data-ps2id-api="true"
                  >
                    <i className="ion-home" />
                    Home
                  </a>
                  <ul className="sub-menu">
                    <li
                      id="menu-item-2447"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2447"
                    >
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://live.21lab.co/nanosoft/"
                        data-ps2id-api="true"
                      >
                        Pre-built Demo 1
                      </a>
                    </li>
                    <li
                      id="menu-item-2448"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2448"
                    >
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://live.21lab.co/nanosoft-2/"
                        data-ps2id-api="true"
                      >
                        Pre-built Demo 2
                      </a>
                    </li>
                    <li
                      id="menu-item-2449"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-2449"
                    >
                      <a
                        href="index.html"
                        aria-current="page"
                        data-ps2id-api="true"
                      >
                        Pre-built Demo 3
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-1797"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1797"
                >
                  <a href="about/index.html" data-ps2id-api="true">
                    <i className="ion-ios-world" />
                    Company
                  </a>
                  <ul className="sub-menu">
                    <li
                      id="menu-item-2178"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2178"
                    >
                      <a href="about/index.html" data-ps2id-api="true">
                        About
                      </a>
                    </li>
                    <li
                      id="menu-item-2174"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2174"
                    >
                      <a href="careers/index.html" data-ps2id-api="true">
                        Careers
                      </a>
                    </li>
                    <li
                      id="menu-item-2175"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2175"
                    >
                      <a href="faq/index.html" data-ps2id-api="true">
                        FAQ
                      </a>
                    </li>
                    <li
                      id="menu-item-2176"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2176"
                    >
                      <a href="contact-us/index.html" data-ps2id-api="true">
                        Contact Us
                      </a>
                    </li>
                    <li
                      id="menu-item-2177"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2177"
                    >
                      <a
                        href="pricing-and-plans/index.html"
                        data-ps2id-api="true"
                      >
                        Pricing and plans
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-1795"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1795"
                >
                  <a href="it-services/index.html" data-ps2id-api="true">
                    <i className="ion-ios-cog" />
                    IT Solutions
                  </a>
                  <ul className="sub-menu">
                    <li
                      id="menu-item-750"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-750"
                    >
                      <a
                        href="it-services/managed-it/index.html"
                        data-ps2id-api="true"
                      >
                        <img
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                          alt={1}
                          data-lazy-src="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/1.svg"
                        />
                        <noscript>
                          &lt;img src="wp-content/uploads/2019/04/1.svg"
                          alt="1"&gt;
                        </noscript>
                        Managed IT
                      </a>
                    </li>
                    <li
                      id="menu-item-751"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-751"
                    >
                      <a
                        href="it-services/it-support/index.html"
                        data-ps2id-api="true"
                      >
                        <img
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                          alt={1}
                          data-lazy-src="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/2.svg"
                        />
                        <noscript>
                          &lt;img src="wp-content/uploads/2019/04/2.svg"
                          alt="1"&gt;
                        </noscript>
                        IT Support
                      </a>
                    </li>
                    <li
                      id="menu-item-747"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-747"
                    >
                      <a
                        href="it-services/cyber-security/index.html"
                        data-ps2id-api="true"
                      >
                        <img
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                          alt={1}
                          data-lazy-src="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/3.svg"
                        />
                        <noscript>
                          &lt;img src="wp-content/uploads/2019/04/3.svg"
                          alt="1"&gt;
                        </noscript>
                        Cyber Security
                      </a>
                    </li>
                    <li
                      id="menu-item-748"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-748"
                    >
                      <a
                        href="it-services/cloud-computing/index.html"
                        data-ps2id-api="true"
                      >
                        <img
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                          alt={1}
                          data-lazy-src="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/4.svg"
                        />
                        <noscript>
                          &lt;img src="wp-content/uploads/2019/04/4.svg"
                          alt="1"&gt;
                        </noscript>
                        Cloud Computing
                      </a>
                    </li>
                    <li
                      id="menu-item-2173"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2173"
                    >
                      <a
                        href="it-services/it-consultancy/index.html"
                        data-ps2id-api="true"
                      >
                        <img
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                          alt={1}
                          data-lazy-src="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/5.svg"
                        />
                        <noscript>
                          &lt;img src="wp-content/uploads/2019/04/5.svg"
                          alt="1"&gt;
                        </noscript>
                        IT Consultancy
                      </a>
                    </li>
                    <li
                      id="menu-item-2172"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2172"
                    >
                      <a
                        href="it-services/custom-software/index.html"
                        data-ps2id-api="true"
                      >
                        <img
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                          alt={1}
                          data-lazy-src="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/6.svg"
                        />
                        <noscript>
                          &lt;img src="wp-content/uploads/2019/04/6.svg"
                          alt="1"&gt;
                        </noscript>
                        Custom Software
                      </a>
                    </li>
                    <li
                      id="menu-item-1793"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1793"
                    >
                      <a href="it-services/index.html" data-ps2id-api="true">
                        <i className="size-48 ion-ios-plus-outline" />
                        All Services
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-2183"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2183"
                >
                  <a href="it-blog/index.html" data-ps2id-api="true">
                    <i className="ion-ios-book" />
                    IT Blog
                  </a>
                </li>
                <li
                  id="menu-item-1798"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1798"
                >
                  <a href="case-studies/index.html" data-ps2id-api="true">
                    <i className="ion-social-buffer" />
                    Case Studies
                  </a>
                </li>
              </ul>{" "}
            </nav>
            <div className="extras">
              <div className="header-info-text">
                Developed by BajrangiTechno | © 2023 BajrangiTechno. All rights
                reserved.{" "}
              </div>
              <div className="social-icons">
                <a href="#" data-tooltip="facebook" target="_blank">
                  <i className="fa fa-facebook-f" />
                </a>
                <a href="#" data-tooltip="twitter" target="_blank">
                  <i className="fa fa-twitter" />
                </a>
                <a href="#" data-tooltip="linkedin" target="_blank">
                  <i className="fa fa-linkedin" />
                </a>
                <a href="#" data-tooltip="installgram" target="_blank">
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
            <a
              href="javascript:;"
              data-target="off-canvas-right"
              className="off-canvas-toggle"
            >
              <span />
            </a>
          </div>
        </div>
        {/* /.site-header-inner */}
      </div>
    </>
  );
};

export default SideNav;
