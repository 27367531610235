// components/Layout.js
import React from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Footer from "./Footer";
import OffCanvas from "./OffCanvas";

const Layout = ({ children }) => {
  return (
    <>
      <div id="site" className="site wrap header-position-left full-content">
        <Header />
        <SideNav />
        {/* /.site-header */}
        {children}
        {/* /.site-content */}

        {/* /#site-footer */}
        <Footer />
      </div>
      {/* /.site-wrapper */}
      <OffCanvas />
      <div id="frame">
        <span className="frame_top" />
        <span className="frame_right" />
        <span className="frame_bottom" />
        <span className="frame_left" />
      </div>
    </>
  );
};

export default Layout;
