import React from "react";

const Home = () => {
  return (
    <>
      <div id="site-content" className="site-content">
        <div id="content-body" className="content-body">
          <div className="content-body-inner wrap">
            {/* The main content */}
            <main
              id="main-content"
              className="main-content"
              itemProp="mainContentOfPage"
            >
              <div className="main-content-inner">
                <div className="content">
                  <section
                    style={{}}
                    className="vc_section vc_custom_1575627675028 vc_section-has-fill"
                  >
                    <div
                      style={{}}
                      data-vc-parallax-image="https://www.youtube.com/watch?v=V8sqRCuvvzE"
                      data-vc-video-bg="https://www.youtube.com/watch?v=V8sqRCuvvzE"
                      className="vc_row wpb_row vc_row-fluid mask vc_custom_1644830343988 vc_row-has-fill vc_video-bg-container"
                    >
                      <canvas />
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-2">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-8">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <h2
                                style={{
                                  fontSize: "2rem",
                                  color: "#ffffff",
                                  lineHeight: "1em",
                                  textAlign: "center",
                                }}
                                className="vc_custom_heading"
                              >
                                Stability in Every Solution – BajrangiTechno,
                                Your Digital Transformation Partner
                              </h2>
                              <div className="wpb_text_column wpb_content_element  vc_custom_1556252144509 white-color aligncenter">
                                <div className="wpb_wrapper">
                                  <h1 className="cd-headline push">
                                    <span> and </span>{" "}
                                    <span className="cd-words-wrapper">
                                      <b className="is-visible">stay running</b>
                                      <b className="">Stay Connected</b>
                                      <b className="">stay protected</b>
                                    </span>
                                  </h1>
                                </div>
                              </div>
                              <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_10 vc_sep_border_width_3 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_white">
                                <span className="vc_sep_holder vc_sep_holder_l">
                                  <span className="vc_sep_line" />
                                </span>
                                <span className="vc_sep_holder vc_sep_holder_r">
                                  <span className="vc_sep_line" />
                                </span>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <div
                                style={{
                                  fontSize: 20,
                                  color: "#ffffff",
                                  textAlign: "center",
                                }}
                                className="vc_custom_heading"
                              >
                                Start getting the support you need. Let us
                                handle IT for you.
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 40 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <div className="wpb_text_column wpb_content_element  aligncenter">
                                <div className="wpb_wrapper">
                                  {/* <div className="button-video">
                                    <a
                                      id="65868f8e25053"
                                      rel="wp-video-lightbox"
                                      href="https://www.youtube.com/watch?v=SZEflIVnhH8&width=640&height=480"
                                      title=""
                                    >
                                      click
                                    </a>{" "}
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-2">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{}}
                      className="vc_row wpb_row vc_row-fluid fix-overflow vc_custom_1575627693206"
                    >
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_2 vc_sep_pos_align_center vc_separator-has-text">
                                <span className="vc_sep_holder vc_sep_holder_l">
                                  <span
                                    style={{
                                      borderColor: "rgba(255,255,255,0.28)",
                                    }}
                                    className="vc_sep_line"
                                  />
                                </span>
                                <h4>
                                  Custom IT services and solutions for your
                                  business
                                </h4>
                                <span className="vc_sep_holder vc_sep_holder_r">
                                  <span
                                    style={{
                                      borderColor: "rgba(255,255,255,0.28)",
                                    }}
                                    className="vc_sep_line"
                                  />
                                </span>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 40 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <ul className="iconlist iconlist iconlist-icon-medium gap-10">
                                <li>
                                  <div className="hover3 aligncenter shadow  vc_custom_1556078491460">
                                    <div className="iconlist-item-icon" />
                                    <div className="iconlist-item-content">
                                      <h4>IT Support</h4>
                                      <p>
                                        We offer a comprehensive range of
                                        managed IT…
                                      </p>
                                      <p>
                                        <a
                                          className="link"
                                          href="it-services/it-support/index.html"
                                        >
                                          Click here
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="hover3 aligncenter shadow  vc_custom_1556078498445">
                                    <div className="iconlist-item-icon" />
                                    <div className="iconlist-item-content">
                                      <h4>IT Consultancy</h4>
                                      <p>
                                        Our IT consulting team will provide you
                                        with the…
                                      </p>
                                      <p>
                                        <a
                                          className="link"
                                          href="it-services/it-consultancy/index.html"
                                        >
                                          Click here
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="hover3 aligncenter shadow  vc_custom_1556078504478">
                                    <div className="iconlist-item-icon" />
                                    <div className="iconlist-item-content">
                                      <h4>Cyber security</h4>
                                      <p>
                                        Protect your business from malware,
                                        hackers…
                                      </p>
                                      <p>
                                        <a
                                          className="link"
                                          href="it-services/cyber-security/index.html"
                                        >
                                          Click here
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="hover3 aligncenter shadow  vc_custom_1556078510134">
                                    <div className="iconlist-item-icon" />
                                    <div className="iconlist-item-content">
                                      <h4>Cloud computing</h4>
                                      <p>
                                        Prevent data loss with encrypted storage
                                        and…
                                      </p>
                                      <p>
                                        <a
                                          className="link"
                                          href="it-services/cloud-computing/index.html"
                                        >
                                          Click here
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="hover3 aligncenter shadow  vc_custom_1556078516240">
                                    <div className="iconlist-item-icon" />
                                    <div className="iconlist-item-content">
                                      <h4>Managed IT</h4>
                                      <p>
                                        Maintenance &amp; monitoring that keeps
                                        your…
                                      </p>
                                      <p>
                                        <a
                                          className="link"
                                          href="it-services/managed-it/index.html"
                                        >
                                          Click here
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className="vc_empty_space"
                                style={{ height: 20 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p style={{ textAlign: "center" }}>
                                    IT services built specifically for your
                                    business.&nbsp;
                                    <a
                                      className="link"
                                      href="it-services/index.html"
                                    >
                                      Find your solution
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 230 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div
                    style={{}}
                    className="vc_row wpb_row vc_row-fluid fix-overflow vc_custom_1575627702918 vc_row-has-fill"
                  >
                    <div className="row-inner">
                      <div className="wpb_column vc_column_container vc_col-sm-3">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper" />
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-6">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1556095626576">
                              <figure className="wpb_wrapper vc_figure">
                                <div className="vc_single_image-wrapper   vc_box_border_grey">
                                  <img
                                    fetchpriority="high"
                                    decoding="async"
                                    width={987}
                                    height={599}
                                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20987%20599'%3E%3C/svg%3E"
                                    className="vc_single_image-img attachment-full"
                                    alt=""
                                    title="ipad"
                                    data-lazy-srcset="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/ipad.png 987w, https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/ipad-300x182.png 300w, https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/ipad-768x466.png 768w"
                                    data-lazy-sizes="(max-width: 987px) 100vw, 987px"
                                    data-lazy-src="wp-content/uploads/2019/04/ipad.png"
                                  />
                                  <noscript>
                                    &lt;img fetchpriority="high"
                                    decoding="async" width="987" height="599"
                                    src="wp-content/uploads/2019/04/ipad.png"
                                    class="vc_single_image-img attachment-full"
                                    alt="" title="ipad"
                                    srcset="https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/ipad.png
                                    987w,
                                    https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/ipad-300x182.png
                                    300w,
                                    https://live.21lab.co/nanosoft-3/wp-content/uploads/2019/04/ipad-768x466.png
                                    768w" sizes="(max-width: 987px) 100vw,
                                    987px" /&gt;
                                  </noscript>
                                </div>
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-3">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <section
                    style={{}}
                    className="vc_section vc_custom_1575627708631 vc_section-has-fill"
                  >
                    <div
                      style={{}}
                      className="vc_row wpb_row vc_row-fluid vc_custom_1575627714361"
                    >
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <h6
                                style={{ fontSize: 18, textAlign: "center" }}
                                className="vc_custom_heading"
                              >
                                we are BajrangiTechno
                              </h6>
                              <div
                                className="vc_empty_space"
                                style={{ height: 6 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <h2
                                style={{ textAlign: "center" }}
                                className="vc_custom_heading"
                              >
                                THE LEADING IT SOLUTIONS COMPANY
                              </h2>
                              <div
                                className="vc_empty_space"
                                style={{ height: 10 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p style={{ textAlign: "center" }}>
                                    Today we’re proud to boast a strong team of
                                    IT engineers who thrive on rolling up their
                                    sleeves and solving your IT problems and
                                    meeting your business needs.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{}}
                      className="vc_row wpb_row vc_row-fluid vc_custom_1575627718093 vc_column-gap-35 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                    >
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-7 vc_col-has-fill">
                          <div className="vc_column-inner vc_custom_1555991134461">
                            <div className="wpb_wrapper">
                              <div
                                className="vc_empty_space"
                                style={{ height: 200 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-5 vc_col-has-fill">
                          <div className="vc_column-inner vc_custom_1555991711406">
                            <div className="wpb_wrapper">
                              <h2
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading"
                              >
                                OUR PEOPLE MAKE US SPECIAL
                              </h2>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <ul className="iconlist iconlist iconlist-icon-mini ">
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      <span style={{ color: "#191937" }}>
                                        Our people love being here
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      <span style={{ color: "#191937" }}>
                                        We have a 95%+ retention rate for
                                        engineers
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      <span style={{ color: "#191937" }}>
                                        We promote long-term relationships with
                                        clients
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      <span style={{ color: "#191937" }}>
                                        Our team are passionate people who
                                        really know your system
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    <a
                                      className="button small primary full"
                                      href="#"
                                    >
                                      Find out more
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{}}
                      className="vc_row wpb_row vc_row-fluid vc_custom_1575627918002 vc_column-gap-35 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                    >
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-5 vc_col-has-fill">
                          <div className="vc_column-inner vc_custom_1555992722983">
                            <div className="wpb_wrapper">
                              <h2
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading"
                              >
                                MORE THAN JUST IT EXPERTISE
                              </h2>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <ul className="iconlist iconlist iconlist-icon-mini ">
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      Manage entire IT infrastructures so you
                                      can focus on growing your business.
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      Provide help and expertise to accomplish a
                                      specific project.
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      Engage with end users and deliver
                                      responsive support on an IT strategy that
                                      aligns with business goals.
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    <a
                                      className="button small primary full"
                                      href="#"
                                    >
                                      Find out more
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-7 vc_col-has-fill">
                          <div className="vc_column-inner vc_custom_1555991373716">
                            <div className="wpb_wrapper">
                              <div
                                className="vc_empty_space"
                                style={{ height: 200 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{}}
                      className="vc_row wpb_row vc_row-fluid vc_custom_1575627907977 vc_column-gap-35 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                    >
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-7 vc_col-has-fill">
                          <div className="vc_column-inner vc_custom_1555991424297">
                            <div className="wpb_wrapper">
                              <div
                                className="vc_empty_space"
                                style={{ height: 200 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-5 vc_col-has-fill">
                          <div className="vc_column-inner vc_custom_1555992705094">
                            <div className="wpb_wrapper">
                              <h2
                                style={{ textAlign: "left" }}
                                className="vc_custom_heading"
                              >
                                GET BACK TO YOUR CORE BUSINESS IN 12 MINUTES
                              </h2>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <ul className="iconlist iconlist iconlist-icon-mini ">
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      <span style={{ color: "#191937" }}>
                                        12 minutes.&nbsp;And a few button
                                        clicks. That’s all it takes to set up
                                        your fully-hosted and managed Aiven
                                        services.
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div className="iconlist-item-icon">
                                      <img
                                        decoding="async"
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        alt="check-w"
                                        data-lazy-src="wp-content/uploads/2019/04/check-w.svg"
                                      />
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2019/04/check-w.svg"
                                        alt="check-w" /&gt;
                                      </noscript>
                                    </div>
                                    <div className="iconlist-item-content">
                                      <span style={{ color: "#191937" }}>
                                        Our services run on highly-available,
                                        dedicated VMs that are encrypted at rest
                                        and transfer.&nbsp;Are you ready to get
                                        back to your core business?
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p>
                                    <a
                                      className="button small primary full"
                                      href="#"
                                    >
                                      Find out more
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{}}
                      className="vc_row wpb_row vc_row-fluid vc_custom_1575627735296"
                    >
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <h6
                                style={{ fontSize: 18, textAlign: "center" }}
                                className="vc_custom_heading"
                              >
                                service delivery KPIS
                              </h6>
                              <div
                                className="vc_empty_space"
                                style={{ height: 6 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <h2
                                style={{ textAlign: "center" }}
                                className="vc_custom_heading"
                              >
                                HOW WE PERFORMED IN MARCH 2019
                              </h2>
                              <div
                                className="vc_empty_space"
                                style={{ height: 100 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1555989440813">
                                <div className="wpb_column vc_column_container vc_col-sm-4">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="counter aligncenter ">
                                        {" "}
                                        <div className="counter-detail">
                                          <h3 className="counter-content">
                                            <span className="counter-prefix" />
                                            <span
                                              className="counter-value"
                                              data-from={0}
                                              data-to={15}
                                              data-speed={1000}
                                            >
                                              0
                                            </span>
                                            <span className="counter-suffix">
                                              secs
                                            </span>
                                          </h3>
                                          <div className="counter-title">
                                            Average Waiting Time
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 60 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-4">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="counter aligncenter ">
                                        {" "}
                                        <div className="counter-detail">
                                          <h3 className="counter-content">
                                            <span className="counter-prefix" />
                                            <span
                                              className="counter-value"
                                              data-from={0}
                                              data-to={2780}
                                              data-speed={1000}
                                            >
                                              0
                                            </span>
                                            <span className="counter-suffix" />
                                          </h3>
                                          <div className="counter-title">
                                            Average Waiting Time
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 60 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-4">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="counter aligncenter ">
                                        {" "}
                                        <div className="counter-detail">
                                          <h3 className="counter-content">
                                            <span className="counter-prefix" />
                                            <span
                                              className="counter-value"
                                              data-from={0}
                                              data-to={90}
                                              data-speed={1000}
                                            >
                                              0
                                            </span>
                                            <span className="counter-suffix">
                                              %
                                            </span>
                                          </h3>
                                          <div className="counter-title">
                                            Same Day Fix (Calls)
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 60 }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    style={{}}
                    className="vc_section mask vc_custom_1575627741486 vc_section-has-fill"
                  >
                    <div style={{}} className="vc_row wpb_row vc_row-fluid">
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <h6
                                style={{
                                  fontSize: 18,
                                  color: "rgba(255,255,255,0.65)",
                                  textAlign: "center",
                                }}
                                className="vc_custom_heading"
                              >
                                industries we serve
                              </h6>
                              <div
                                className="vc_empty_space"
                                style={{ height: 6 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <h2
                                style={{
                                  color: "#ffffff",
                                  textAlign: "center",
                                }}
                                className="vc_custom_heading"
                              >
                                WE WORK WITH GLOBAL BRANDS
                              </h2>
                              <div
                                className="vc_empty_space"
                                style={{ height: 10 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-3">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{}} className="vc_row wpb_row vc_row-fluid">
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-2">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-8">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p style={{ textAlign: "center" }}>
                                    <span style={{ color: "#ffffff" }}>
                                      Our vertical solutions expertise allows
                                      your business to streamline workflow, and
                                      increase productivity.
                                      <br />
                                      No matter the business, NanoSoft has you
                                      covered with industry compliant solutions.
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 40 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <p style={{ textAlign: "center" }}>
                                    <a className="button small white" href="#">
                                      View Case Studies
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 70 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-2">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{}}
                      className="vc_row wpb_row vc_row-fluid vc_custom_1554110332900 vc_row-o-content-middle vc_row-flex"
                    >
                      <div className="row-inner">
                        <div className="wpb_column vc_column_container vc_col-sm-1/5 vc_col-lg-1/5 vc_col-xs-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="wpb_single_image wpb_content_element vc_align_center">
                                <figure className="wpb_wrapper vc_figure">
                                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      className="vc_single_image-img attachment-full"
                                      alt=""
                                      title="j-w"
                                      data-lazy-src="wp-content/uploads/2018/08/j-w.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/j-w.svg"
                                      class="vc_single_image-img
                                      attachment-full" alt="" title="j-w" /&gt;
                                    </noscript>
                                  </div>
                                </figure>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-1/5 vc_col-lg-1/5 vc_col-xs-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="wpb_single_image wpb_content_element vc_align_center">
                                <figure className="wpb_wrapper vc_figure">
                                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      className="vc_single_image-img attachment-full"
                                      alt=""
                                      title="box-w"
                                      data-lazy-src="wp-content/uploads/2018/08/box-w.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/box-w.svg"
                                      class="vc_single_image-img
                                      attachment-full" alt="" title="box-w"
                                      /&gt;
                                    </noscript>
                                  </div>
                                </figure>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-1/5 vc_col-lg-1/5 vc_col-xs-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="wpb_single_image wpb_content_element vc_align_center">
                                <figure className="wpb_wrapper vc_figure">
                                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      className="vc_single_image-img attachment-full"
                                      alt=""
                                      title="k-w"
                                      data-lazy-src="wp-content/uploads/2018/08/k-w.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/k-w.svg"
                                      class="vc_single_image-img
                                      attachment-full" alt="" title="k-w" /&gt;
                                    </noscript>
                                  </div>
                                </figure>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-1/5 vc_col-lg-1/5 vc_col-xs-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="wpb_single_image wpb_content_element vc_align_center">
                                <figure className="wpb_wrapper vc_figure">
                                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      className="vc_single_image-img attachment-full"
                                      alt=""
                                      title="n-w"
                                      data-lazy-src="wp-content/uploads/2018/08/n-w.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/n-w.svg"
                                      class="vc_single_image-img
                                      attachment-full" alt="" title="n-w" /&gt;
                                    </noscript>
                                  </div>
                                </figure>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-1/5 vc_col-lg-1/5 vc_col-xs-4">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="wpb_single_image wpb_content_element vc_align_center">
                                <figure className="wpb_wrapper vc_figure">
                                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      className="vc_single_image-img attachment-full"
                                      alt=""
                                      title="fu-w"
                                      data-lazy-src="wp-content/uploads/2018/08/fu-w.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/fu-w.svg"
                                      class="vc_single_image-img
                                      attachment-full" alt="" title="fu-w" /&gt;
                                    </noscript>
                                  </div>
                                </figure>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 30 }}
                              >
                                <span className="vc_empty_space_inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div style={{}} className="vc_row wpb_row vc_row-fluid">
                    <div className="row-inner">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner vc_custom_1575628209657">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid shadow vc_custom_1575628191030 vc_row-has-fill">
                              <div className="custom-border wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
                                <div className="vc_column-inner vc_custom_1575628147273">
                                  <div className="wpb_wrapper">
                                    <div className="testimonial style2  has-image">
                                      <div className="testimonial-wrap">
                                        <div className="testimonial-content">
                                          <div className="testimonial-image">
                                            <img
                                              width={600}
                                              height={600}
                                              decoding="async"
                                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20600%20600'%3E%3C/svg%3E"
                                              alt="Mrs. Jeffrey"
                                              data-lazy-src="wp-content/uploads/2018/08/7.jpg"
                                            />
                                            <noscript>
                                              &lt;img width="600" height="600"
                                              decoding="async"
                                              src="wp-content/uploads/2018/08/7.jpg"
                                              alt="Mrs. Jeffrey" /&gt;
                                            </noscript>
                                          </div>
                                          <div className="testimonial-text">
                                            <p>
                                              Being back with NanoSoft just
                                              gives me peace of mind knowing
                                              that my technology is functioning
                                              seamlessly behind the scenes
                                              without it interfering with and
                                              disrupting our day-to-day
                                              operations.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="author-info">
                                          <h6 className="author-name">
                                            Mrs. Jeffrey
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
                                <div className="vc_column-inner vc_custom_1575628179201">
                                  <div className="wpb_wrapper">
                                    <div className="testimonial style2  has-image">
                                      <div className="testimonial-wrap">
                                        <div className="testimonial-content">
                                          <div className="testimonial-image">
                                            <img
                                              width={600}
                                              height={600}
                                              decoding="async"
                                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20600%20600'%3E%3C/svg%3E"
                                              alt="Mr. Richard"
                                              data-lazy-src="wp-content/uploads/2018/08/2.jpg"
                                            />
                                            <noscript>
                                              &lt;img width="600" height="600"
                                              decoding="async"
                                              src="wp-content/uploads/2018/08/2.jpg"
                                              alt="Mr. Richard" /&gt;
                                            </noscript>
                                          </div>
                                          <div className="testimonial-text">
                                            <p>
                                              Very late in the day, I received a
                                              request from our director to
                                              obtain a quote for software and
                                              laptops. We needed them by noon
                                              the next day. But John Doe was
                                              able to get those to us first
                                              thing in the morning.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="author-info">
                                          <h6 className="author-name">
                                            Mr. Richard
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{}}
                    className="vc_row wpb_row vc_row-fluid vc_custom_1575627758295"
                  >
                    <div className="row-inner">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                              <div className="wpb_column vc_column_container vc_col-sm-3">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper" />
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <h6
                                      style={{
                                        fontSize: 18,
                                        textAlign: "center",
                                      }}
                                      className="vc_custom_heading"
                                    >
                                      why choose us
                                    </h6>
                                    <div
                                      className="vc_empty_space"
                                      style={{ height: 6 }}
                                    >
                                      <span className="vc_empty_space_inner" />
                                    </div>
                                    <h2
                                      style={{ textAlign: "center" }}
                                      className="vc_custom_heading"
                                    >
                                      6 REASONS TO PARTNER WITH US
                                    </h2>
                                    <div
                                      className="vc_empty_space"
                                      style={{ height: 60 }}
                                    >
                                      <span className="vc_empty_space_inner" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-3">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper" />
                                </div>
                              </div>
                            </div>
                            <ul className="iconlist iconlist iconlist-icon-small columns-3 gap-60 number">
                              <li>
                                <div>
                                  <div className="iconlist-item-icon">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      alt="conversations-4872_af60b258-251e-41af-b238-dfb706d7b3d4"
                                      data-lazy-src="wp-content/uploads/2018/08/conversations-4872_af60b258-251e-41af-b238-dfb706d7b3d4.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/conversations-4872_af60b258-251e-41af-b238-dfb706d7b3d4.svg"
                                      alt="conversations-4872_af60b258-251e-41af-b238-dfb706d7b3d4"
                                      /&gt;
                                    </noscript>
                                  </div>
                                  <div className="iconlist-item-content">
                                    <h4>Quick response</h4>
                                    <p>
                                      We can log in to your PC or server
                                      remotely and resolve many issues
                                      immediately without the wait for a
                                      technician to travel to your location.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <div className="iconlist-item-icon">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      alt="diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0"
                                      data-lazy-src="wp-content/uploads/2018/09/diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/09/diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0.svg"
                                      alt="diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0"
                                      /&gt;
                                    </noscript>
                                  </div>
                                  <div className="iconlist-item-content">
                                    <h4>Experienced</h4>
                                    <p>
                                      In more than 30 years of IT outsourcing,
                                      we have gained experience in a wide
                                      spectrum of technologies, industries, and
                                      application types.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <div className="iconlist-item-icon">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      alt="messaging-app-4876_473fc710-9ecc-4785-9e78-8c9f00ae9498"
                                      data-lazy-src="wp-content/uploads/2018/08/messaging-app-4876_473fc710-9ecc-4785-9e78-8c9f00ae9498.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/messaging-app-4876_473fc710-9ecc-4785-9e78-8c9f00ae9498.svg"
                                      alt="messaging-app-4876_473fc710-9ecc-4785-9e78-8c9f00ae9498"
                                      /&gt;
                                    </noscript>
                                  </div>
                                  <div className="iconlist-item-content">
                                    <h4>No geek speak</h4>
                                    <p>
                                      You deserve to have your questions
                                      answered in plain English. Our technicians
                                      will clearly explain what is happening so
                                      you understand.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <div className="iconlist-item-icon">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      alt="flag-2979_1fd1d414-4b4f-4887-a94a-493ba8e0b0c7"
                                      data-lazy-src="wp-content/uploads/2018/08/flag-2979_1fd1d414-4b4f-4887-a94a-493ba8e0b0c7.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/flag-2979_1fd1d414-4b4f-4887-a94a-493ba8e0b0c7.svg"
                                      alt="flag-2979_1fd1d414-4b4f-4887-a94a-493ba8e0b0c7"
                                      /&gt;
                                    </noscript>
                                  </div>
                                  <div className="iconlist-item-content">
                                    <h4>Business savvy</h4>
                                    <p>
                                      We design, evaluate and justify technology
                                      solutions from a thorough understanding of
                                      the business benefit for your company.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <div className="iconlist-item-icon">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      alt="source-code-1900_d2c3a8cb-9d49-47a9-aa2d-152f24e446d6"
                                      data-lazy-src="wp-content/uploads/2018/08/source-code-1900_d2c3a8cb-9d49-47a9-aa2d-152f24e446d6.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/source-code-1900_d2c3a8cb-9d49-47a9-aa2d-152f24e446d6.svg"
                                      alt="source-code-1900_d2c3a8cb-9d49-47a9-aa2d-152f24e446d6"
                                      /&gt;
                                    </noscript>
                                  </div>
                                  <div className="iconlist-item-content">
                                    <h4>One Stop Shop</h4>
                                    <p>
                                      We handle all aspects of your IT
                                      infrastructure including hardware,
                                      software management and any other related
                                      technology needs.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <div className="iconlist-item-icon">
                                    <img
                                      decoding="async"
                                      src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                      alt="happy-emoji-2947_45d5bb03-c67d-4e73-a316-a5e7f4a9f2f7"
                                      data-lazy-src="wp-content/uploads/2018/08/happy-emoji-2947_45d5bb03-c67d-4e73-a316-a5e7f4a9f2f7.svg"
                                    />
                                    <noscript>
                                      &lt;img decoding="async"
                                      src="wp-content/uploads/2018/08/happy-emoji-2947_45d5bb03-c67d-4e73-a316-a5e7f4a9f2f7.svg"
                                      alt="happy-emoji-2947_45d5bb03-c67d-4e73-a316-a5e7f4a9f2f7"
                                      /&gt;
                                    </noscript>
                                  </div>
                                  <div className="iconlist-item-content">
                                    <h4>100% Satisfaction Guarantee</h4>
                                    <p>
                                      We want you to be completely satisfied
                                      with our services. We will do whatever it
                                      takes to make you happy. No hassles, no
                                      problems.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{}}
                    className="vc_row wpb_row vc_row-fluid mask vc_custom_1575627765246 vc_row-has-fill"
                  >
                    <div className="row-inner">
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper" />
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                        <div className="vc_column-inner vc_custom_1556097596478">
                          <div className="wpb_wrapper">
                            <h3
                              style={{
                                lineHeight: "1.1em",
                                textAlign: "center",
                              }}
                              className="vc_custom_heading"
                            >
                              world class IT solutions partner of choice
                            </h3>
                            <div
                              className="vc_empty_space"
                              style={{ height: 30 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <h2
                              style={{
                                fontSize: 72,
                                color: "#ff0055",
                                lineHeight: 1,
                                textAlign: "center",
                              }}
                              className="vc_custom_heading"
                            >
                              More than 6
                            </h2>
                            <h5
                              style={{ textAlign: "center" }}
                              className="vc_custom_heading"
                            >
                              YEARS OF EXPERINCE
                            </h5>
                            <div
                              className="vc_empty_space"
                              style={{ height: 30 }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p style={{ textAlign: "center" }}>
                                  <a
                                    className="button primary full"
                                    href="contact-us/index.html"
                                  >
                                    Get a free consultation
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{}}
                    className="vc_row wpb_row vc_row-fluid vc_custom_1575627770835"
                  >
                    <div className="row-inner">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                              <div className="wpb_column vc_column_container vc_col-sm-3">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper" />
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <h6
                                      style={{
                                        fontSize: 18,
                                        textAlign: "center",
                                      }}
                                      className="vc_custom_heading"
                                    >
                                      form our blog
                                    </h6>
                                    <div
                                      className="vc_empty_space"
                                      style={{ height: 6 }}
                                    >
                                      <span className="vc_empty_space_inner" />
                                    </div>
                                    <h2
                                      style={{ textAlign: "center" }}
                                      className="vc_custom_heading"
                                    >
                                      IDEAS THAT DRIVE TOMORROW'S INNOVATION
                                    </h2>
                                    <div
                                      className="vc_empty_space"
                                      style={{ height: 60 }}
                                    >
                                      <span className="vc_empty_space_inner" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-3">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper" />
                                </div>
                              </div>
                            </div>
                            <div className="blog-shortcode  has-post-content post-thumbnail-cover style2">
                              <div className="blog-entries">
                                <div
                                  className="entries-wrapper content-inner blog-grid"
                                  data-grid-normal=""
                                  data-columns={4}
                                >
                                  <div className="post post-204 type-post status-publish format-standard has-post-thumbnail hentry category-success-stories tag-lighthouse tag-technology">
                                    <div className="post-inner">
                                      <a href="improving-lives-with-technology-hse-lighthouse-project/index.html">
                                        <div className="post-image">
                                          <div className="post-meta">
                                            <div className="post-avatar">
                                              <img
                                                alt=""
                                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2080%2080'%3E%3C/svg%3E"
                                                data-lazy-srcset="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&d=mm&r=g 2x"
                                                className="avatar avatar-80 photo"
                                                height={80}
                                                width={80}
                                                data-lazy-src="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&d=mm&r=g"
                                              />
                                              <noscript>
                                                &lt;img alt=''
                                                src='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&amp;amp;d=mm&amp;amp;r=g'
                                                srcset='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&amp;#038;d=mm&amp;#038;r=g
                                                2x' class='avatar avatar-80
                                                photo' height='80' width='80'
                                                /&gt;
                                              </noscript>{" "}
                                            </div>
                                            <div className="post-author-content">
                                              <span>by</span>
                                              <span className="post-name">
                                                linethemes
                                              </span>
                                            </div>
                                          </div>
                                          <img
                                            decoding="async"
                                            className=""
                                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20800%20400'%3E%3C/svg%3E"
                                            width={800}
                                            height={400}
                                            alt="imgix-391813-unsplash(1)"
                                            title="imgix-391813-unsplash(1)"
                                            data-lazy-src="wp-content/uploads/2018/05/imgix-391813-unsplash1-800x400.jpg"
                                          />
                                          <noscript>
                                            &lt;img decoding="async" class=""
                                            src="wp-content/uploads/2018/05/imgix-391813-unsplash1-800x400.jpg"
                                            width="800" height="400"
                                            alt="imgix-391813-unsplash(1)"
                                            title="imgix-391813-unsplash(1)"
                                            /&gt;
                                          </noscript>{" "}
                                        </div>
                                        <div className="post-wrap">
                                          <span className="post-date">
                                            May 8, 2018
                                          </span>
                                          <h2 className="post-title">
                                            Improving lives with technology –
                                            HSE lighthouse project
                                          </h2>
                                          <div className="post-content">
                                            The ‘Lighthouse Projects’ are in the
                                            clinical disciplines of the chronic
                                            diseases Epilepsy, Haemophilia and
                                            Bipolar Disorder. The epilepsy L...{" "}
                                          </div>
                                          <span className="button">
                                            Continue →{" "}
                                          </span>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="post post-192 type-post status-publish format-standard has-post-thumbnail hentry category-news-events tag-benefits tag-managed-services">
                                    <div className="post-inner">
                                      <a href="tips-to-make-your-workforce-a-security-front-line/index.html">
                                        <div className="post-image">
                                          <div className="post-meta">
                                            <div className="post-avatar">
                                              <img
                                                alt=""
                                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2080%2080'%3E%3C/svg%3E"
                                                data-lazy-srcset="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&d=mm&r=g 2x"
                                                className="avatar avatar-80 photo"
                                                height={80}
                                                width={80}
                                                data-lazy-src="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&d=mm&r=g"
                                              />
                                              <noscript>
                                                &lt;img alt=''
                                                src='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&amp;amp;d=mm&amp;amp;r=g'
                                                srcset='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&amp;#038;d=mm&amp;#038;r=g
                                                2x' class='avatar avatar-80
                                                photo' height='80' width='80'
                                                /&gt;
                                              </noscript>{" "}
                                            </div>
                                            <div className="post-author-content">
                                              <span>by</span>
                                              <span className="post-name">
                                                linethemes
                                              </span>
                                            </div>
                                          </div>
                                          <img
                                            decoding="async"
                                            className=""
                                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20800%20400'%3E%3C/svg%3E"
                                            width={800}
                                            height={400}
                                            alt="rawpixel-com-567026-unsplash(1)"
                                            title="rawpixel-com-567026-unsplash(1)"
                                            data-lazy-src="wp-content/uploads/2018/05/rawpixel-com-567026-unsplash1-800x400.jpg"
                                          />
                                          <noscript>
                                            &lt;img decoding="async" class=""
                                            src="wp-content/uploads/2018/05/rawpixel-com-567026-unsplash1-800x400.jpg"
                                            width="800" height="400"
                                            alt="rawpixel-com-567026-unsplash(1)"
                                            title="rawpixel-com-567026-unsplash(1)"
                                            /&gt;
                                          </noscript>{" "}
                                        </div>
                                        <div className="post-wrap">
                                          <span className="post-date">
                                            May 8, 2018
                                          </span>
                                          <h2 className="post-title">
                                            Tips to make your workforce a
                                            security front line
                                          </h2>
                                          <div className="post-content">
                                            Cyber security is something that is
                                            constantly on our mind here at Unit.
                                            This is because, according to
                                            Bloomberg, cyber security related
                                            iss...{" "}
                                          </div>
                                          <span className="button">
                                            Continue →{" "}
                                          </span>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="post post-193 type-post status-publish format-standard has-post-thumbnail hentry category-success-stories tag-health tag-hospital tag-scan">
                                    <div className="post-inner">
                                      <a href="scan-index-manager-delivers-productivity-at-beaumont-hospital/index.html">
                                        <div className="post-image">
                                          <div className="post-meta">
                                            <div className="post-avatar">
                                              <img
                                                alt=""
                                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2080%2080'%3E%3C/svg%3E"
                                                data-lazy-srcset="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&d=mm&r=g 2x"
                                                className="avatar avatar-80 photo"
                                                height={80}
                                                width={80}
                                                data-lazy-src="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&d=mm&r=g"
                                              />
                                              <noscript>
                                                &lt;img alt=''
                                                src='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&amp;amp;d=mm&amp;amp;r=g'
                                                srcset='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&amp;#038;d=mm&amp;#038;r=g
                                                2x' class='avatar avatar-80
                                                photo' height='80' width='80'
                                                /&gt;
                                              </noscript>{" "}
                                            </div>
                                            <div className="post-author-content">
                                              <span>by</span>
                                              <span className="post-name">
                                                linethemes
                                              </span>
                                            </div>
                                          </div>
                                          <img
                                            decoding="async"
                                            className=""
                                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20800%20400'%3E%3C/svg%3E"
                                            width={800}
                                            height={400}
                                            alt="john-schnobrich-520023-unsplash(1)"
                                            title="john-schnobrich-520023-unsplash(1)"
                                            data-lazy-src="wp-content/uploads/2018/05/john-schnobrich-520023-unsplash1-800x400.jpg"
                                          />
                                          <noscript>
                                            &lt;img decoding="async" class=""
                                            src="wp-content/uploads/2018/05/john-schnobrich-520023-unsplash1-800x400.jpg"
                                            width="800" height="400"
                                            alt="john-schnobrich-520023-unsplash(1)"
                                            title="john-schnobrich-520023-unsplash(1)"
                                            /&gt;
                                          </noscript>{" "}
                                        </div>
                                        <div className="post-wrap">
                                          <span className="post-date">
                                            May 8, 2018
                                          </span>
                                          <h2 className="post-title">
                                            Scan &amp; index manager delivers
                                            productivity at beaumont hospital
                                          </h2>
                                          <div className="post-content">
                                            Beaumont Hospital is a large
                                            academic teaching hospital 5km north
                                            of Dublin City centre. They provide
                                            emergency and acute care services
                                            acro...{" "}
                                          </div>
                                          <span className="button">
                                            Continue →{" "}
                                          </span>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="post post-194 type-post status-publish format-video has-post-thumbnail hentry category-success-stories tag-customer tag-video post_format-post-format-video">
                                    <div className="post-inner">
                                      <a href="partnering-with-it-provider-helps-erie-manufacturing-company-thrive-in-21st-century/index.html">
                                        <div className="post-image">
                                          <div className="post-meta">
                                            <div className="post-avatar">
                                              <img
                                                alt=""
                                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2080%2080'%3E%3C/svg%3E"
                                                data-lazy-srcset="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&d=mm&r=g 2x"
                                                className="avatar avatar-80 photo"
                                                height={80}
                                                width={80}
                                                data-lazy-src="https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&d=mm&r=g"
                                              />
                                              <noscript>
                                                &lt;img alt=''
                                                src='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=80&amp;amp;d=mm&amp;amp;r=g'
                                                srcset='https://secure.gravatar.com/avatar/9019e5866b246d894d49125909178aeb?s=160&amp;#038;d=mm&amp;#038;r=g
                                                2x' class='avatar avatar-80
                                                photo' height='80' width='80'
                                                /&gt;
                                              </noscript>{" "}
                                            </div>
                                            <div className="post-author-content">
                                              <span>by</span>
                                              <span className="post-name">
                                                linethemes
                                              </span>
                                            </div>
                                          </div>
                                          <img
                                            decoding="async"
                                            className=""
                                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20800%20400'%3E%3C/svg%3E"
                                            width={800}
                                            height={400}
                                            alt="285-jir-60871-nam-eye-id-392451-jpeg(1)"
                                            title="285-jir-60871-nam-eye-id-392451-jpeg(1)"
                                            data-lazy-src="wp-content/uploads/2018/05/285-jir-60871-nam-eye-id-392451-jpeg1-800x400.jpg"
                                          />
                                          <noscript>
                                            &lt;img decoding="async" class=""
                                            src="wp-content/uploads/2018/05/285-jir-60871-nam-eye-id-392451-jpeg1-800x400.jpg"
                                            width="800" height="400"
                                            alt="285-jir-60871-nam-eye-id-392451-jpeg(1)"
                                            title="285-jir-60871-nam-eye-id-392451-jpeg(1)"
                                            /&gt;
                                          </noscript>{" "}
                                        </div>
                                        <div className="post-wrap">
                                          <span className="post-date">
                                            May 8, 2018
                                          </span>
                                          <h2 className="post-title">
                                            Partnering with IT provider helps
                                            erie manufacturing company thrive in
                                            21st century
                                          </h2>
                                          <div className="post-content">
                                            Berman Bedding, Inc. has been in
                                            business since 1912. But when this
                                            mattress manufacturer started
                                            producing medical pads in the 1950s,
                                            it re...{" "}
                                          </div>
                                          <span className="button">
                                            Continue →{" "}
                                          </span>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.content */}
              </div>
              {/* /.main-content-inner */}
            </main>
            {/* /.main-content */}
          </div>
          {/* /.content-body-inner */}
        </div>
        {/* /.content-body */}
      </div>
    </>
  );
};

export default Home;
